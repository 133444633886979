/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactElement, useContext } from 'react';
import { isEmpty } from 'lodash';
import { ListItem } from '@lesmills-international/components';
import { useTheme } from '@mui/styles';
import { navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { PendingCancellationMessage, RichTextWrapper, SectionTitle } from './styles';
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import { SUBSCRIPTION_STATES, SUBSCRIPTION_TYPES } from '../../constants/subscription';
import {
  formatActiveDesc,
  formatSubscriptionDetail,
  getRetailDescription,
  getIapSubscriptionInfo,
  formatPendingCancelDesc,
  getResellerdesc,
  formatTrialingTitle,
} from './utils';
import { getBillingPeriod } from '../../utils/currencyPromotions';
import {
  SignupChannel,
  SubscriptionType,
  getSubscriptionType,
  isFullyCanceled,
  isPendingCancellation,
  isSubscriptionCanceled,
  isSubscriptionOnHold,
} from '../../utils/subscriptions';
import ROUTES from '../../../../../src/constants/routes';
import { useIsRolloverAllowed } from '../../hooks/useIsRolloverAllowed';

interface Props {
  handleRestartSubscription: () => void;
  handleCancelSubscription: () => void;
  data: Pick<
    Queries.PrismicAccountOverviewPageData & Queries.PrismicSignupChannelsData,
    | 'subscription_status_title'
    | 'subscription_status_reseller_recurring_active'
    | 'subscription_status_pending_cancellation'
    | 'subscription_status_ended'
    | 'subscription_status_active'
    | 'subscription_section_title'
    | 'monthly_subscription'
    | 'trimonthly_subscription'
    | 'annual_subscription'
    | 'trialing'
    | 'restart_subscription'
    | 'cancel_subscription'
    | 'signup_channel'
    | 'iap_subscriptions'
    | 'restart_subscription_pending_cancellation'
    | 'restart_subscription_cancellation'
    | 'subscription_status_on_hold'
    | 'subscription_status_renewal'
  >;
}

type ListItemType = {
  title: string;
  description?: string | React.ReactElement;
  onClick?: () => void;
  testId?: string;
};

const SubscriptionSection = ({
  data,
  handleRestartSubscription,
  handleCancelSubscription,
}: Props) => {
  const {
    subscription_status_title,
    subscription_status_reseller_recurring_active,
    subscription_status_pending_cancellation,
    subscription_status_ended,
    subscription_status_active,
    monthly_subscription,
    trimonthly_subscription,
    annual_subscription,
    trialing,
    subscription_section_title,
    restart_subscription,
    cancel_subscription,
    signup_channel,
    iap_subscriptions,
    subscription_status_on_hold,
    subscription_status_renewal,
  } = data;

  const { type, lmodSubscription, tier, signupChannel, hasActiveSubscription } =
    useContext(userContext);
  const { themeColor } = useTheme();

  // "RETAIL" | "AFFILIATE" | "RESELLER" | "IAP"
  const subscriptionType = getSubscriptionType({
    subscription: lmodSubscription,
    type: type as SubscriptionType,
    userSignupChannel: signupChannel,
    signupChannelList: signup_channel as unknown as SignupChannel[],
  });

  const state = lmodSubscription?.state || '';

  const isTrialing = lmodSubscription?.state === SUBSCRIPTION_STATES.TRIALING;
  const isRecurring = !lmodSubscription?.cancel_at_end_of_period;
  const isIAPUser = subscriptionType === 'IAP';
  const isD2CUser = subscriptionType === 'AFFILIATE' || subscriptionType === 'RETAIL';

  const { product } = lmodSubscription || {};

  const isRolloverAllowed = useIsRolloverAllowed(product);

  // return null when user has no active subscription and not an IAP user
  if (!hasActiveSubscription && isEmpty(lmodSubscription) && !isIAPUser) {
    return null;
  }

  const billingPeriod = getBillingPeriod(lmodSubscription?.product?.product_price_point?.interval, {
    quarterly: trimonthly_subscription,
    yearly: annual_subscription,
    monthly: monthly_subscription,
  });

  const activeStateTitle = formatSubscriptionDetail(subscription_status_title, tier, billingPeriod);

  const { iap_subscription_title, iap_subscription_description } =
    getIapSubscriptionInfo(
      signupChannel,
      iap_subscriptions as Queries.PrismicAccountOverviewPageDataIapSubscriptionsItem[]
    ) || {};

  const iapTitle = formatSubscriptionDetail(iap_subscription_title, tier);

  const trailingTitle = formatTrialingTitle(activeStateTitle, trialing || '');
  const title = isTrialing ? trailingTitle : activeStateTitle;

  const activeDescription = formatActiveDesc(
    subscription_status_active,
    subscription_status_renewal,
    lmodSubscription,
    isRolloverAllowed
  );

  const pendingCancellationDescription = (
    <PendingCancellationMessage>
      {formatPendingCancelDesc(subscription_status_pending_cancellation, lmodSubscription)}
    </PendingCancellationMessage>
  );

  const isActive = state === SUBSCRIPTION_STATES.ACTIVE || state === SUBSCRIPTION_STATES.TRIALING;

  const enableOnClick = isD2CUser && (isActive || isPendingCancellation(state));

  const onClick = enableOnClick ? () => navigate(ROUTES().SUBSCRIPTION) : undefined;

  // Retail Description
  const retailDescription = getRetailDescription({
    state,
    active: activeDescription,
    canceled: subscription_status_ended || '',
    pendingCancellation: pendingCancellationDescription,
    onHold: subscription_status_on_hold || '',
  });

  // Reseller Description
  const resellerDescription = getResellerdesc({
    state,
    isRecurring,
    active: subscription_status_reseller_recurring_active || '',
    canceled: subscription_status_ended || '',
    pendingCancellation: pendingCancellationDescription || '',
    onHold: subscription_status_on_hold || '',
  });

  // IAP Description
  const IAPDescription = iap_subscription_description ? (
    <RichTextWrapper>
      <RichText
        key={iap_subscription_description?.text}
        render={iap_subscription_description?.richText || ''}
      />
    </RichTextWrapper>
  ) : (
    ''
  );

  const fullyCanceledReseller = subscriptionType === 'RESELLER' && isFullyCanceled(state);
  const canceledD2C = isD2CUser && isSubscriptionCanceled(state);

  const isOnHold = isSubscriptionOnHold(state);

  // No IAP & No RESELLER
  // Is either Active or Trialing state
  // Is On_Hold state
  const showCancellationButton =
    !['IAP', 'RESELLER'].includes(subscriptionType) && (isActive || isOnHold);

  // list item title
  const listItemTitle = isIAPUser ? iapTitle : title;

  // list item description
  const ListItemDescriptionMapping: Record<keyof typeof SUBSCRIPTION_TYPES, string | ReactElement> =
    {
      [SUBSCRIPTION_TYPES.RETAIL]: retailDescription,
      [SUBSCRIPTION_TYPES.AFFILIATE]: retailDescription,
      [SUBSCRIPTION_TYPES.RESELLER]: resellerDescription,
      [SUBSCRIPTION_TYPES.IAP]: IAPDescription,
    };

  const items: ListItemType[] = [
    {
      title: listItemTitle,
      description: ListItemDescriptionMapping[subscriptionType],
      onClick,
      testId: 'subscription-type-info',
    },
  ];

  // display restart subscription button when it's canceled d2c or fully canceled reseller
  if (canceledD2C || fullyCanceledReseller) {
    items.push({
      title: restart_subscription || '',
      onClick: () => handleRestartSubscription(),
    });
  }

  if (showCancellationButton) {
    items.push({
      title: cancel_subscription || '',
      onClick: () => handleCancelSubscription(),
    });
  }

  return (
    <div>
      <SectionTitle>{subscription_section_title}</SectionTitle>
      <ListItem theme={themeColor} items={items} />
    </div>
  );
};
export default SubscriptionSection;
